<template>
  <div>
    <b-container class="no-gutters bg-white">
      <div class="py-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Branch Name (EN)"
              placeholder="Branch Name (EN)"
              v-model="v.form.name_en.$model"
              :v="v.form.name_en"
              :isValidate="v.form.name_en.$error"
              type="text"
              name="branch_name_en"
              isRequired
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Branch Name (TH)"
              placeholder="Branch Name (TH)"
              type="text"
              name="branch_name_th"
              v-model="v.form.name.$model"
              :v="v.form.name"
              :isValidate="v.form.name.$error"
              isRequired
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Short Name"
              placeholder="Short Name"
              type="text"
              name="short_name"
              v-model="form.short_name"
              :v="v.form.short_name"
              :isValidate="v.form.short_name.$error"
              isRequired
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Telephone No"
              placeholder="Telephone No"
              type="text"
              name="telephone"
              v-model="form.telephone"
              @onKeypress="isNumber"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <!-- <label class="label-text"> <b>Open Day</b> </label> -->
            <InputText
              textFloat="Open Day"
              placeholder="Open Day"
              type="text"
              name="Open Day"
              v-model="form.open_day"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="12"
            xs="12"
            class="my-2 my-md-0 d-flex align-items-center"
          >
            <!-- <b-form-checkbox
              id="allowe-change"
              v-model="form.allow_time_change"
              :value="1"
              :unchecked-value="0"
              name="allowe-change"
            >
              Allow To Change Time
            </b-form-checkbox> -->
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <label class="label-text"> <b>Open Time (HH:MM)</b> </label>
            <div class="input-container">
              <datetime
                type="time"
                :input-style="styleDatetime"
                placeholder="HH:MM"
                format="HH:mm"
                value-zone="Asia/Bangkok"
                ref="TimeInput"
                v-model="form.open_time"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.TimeInput.isOpen = true"
              >
                <font-awesome-icon icon="clock" class="pointer" />
              </div>
            </div>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <label class="label-text"> <b>Close Time (HH:MM)</b> </label>
            <div class="input-container">
              <datetime
                type="time"
                :input-style="styleDatetime"
                placeholder="HH:MM"
                format="HH:mm"
                v-model="form.close_time"
                value-zone="Asia/Bangkok"
                ref="closeTimeInput"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.closeTimeInput.isOpen = true"
              >
                <font-awesome-icon icon="clock" class="pointer" />
              </div>
            </div>
          </b-col>
          <b-col class="mt-3">
            <label class="label-text">
              Type <span class="text-danger">*</span>
            </label>

            <b-form-checkbox-group
              class="mt-1"
              id="checkbox-group-1"
              v-model="form.sales_channel"
              :options="type"
              value-field="id"
              text-field="name"
              name="branch-type"
            ></b-form-checkbox-group>

            <div v-if="v.form.sales_channel && v.form.sales_channel.$error">
              <span
                class="text-error"
                v-if="v.form.sales_channel.required == false"
                >Please Select one.</span
              >
            </div>
          </b-col>
          <div class="break-normal"></div>
          <b-col
            lg="6"
            md="6"
            sm="12"
            xs="12"
            class="mt-3 d-flex align-items-center"
          >
            <b-form-checkbox
              id="allowe-change"
              v-model="form.allow_time_change"
              :value="1"
              :unchecked-value="0"
              name="allowe-change"
            >
              Allow To Change Time (Transaction)
            </b-form-checkbox>
          </b-col>
          <div class="break-normal"></div>
          <b-col
            lg="6"
            md="6"
            sm="12"
            xs="12"
            class="mt-3 d-flex align-items-center"
          >
            <b-form-checkbox
              id="used-allow-change"
              v-model="form.allow_time_change_service"
              :value="1"
              :unchecked-value="0"
              name="used-allow-change"
            >
              Allow To Change Time (Used Service)
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr class="my-3" />
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Branch Code"
              placeholder="Branch Code"
              isRequired
              type="text"
              name="branch_code"
              v-model="v.form.store_code.$model"
              :v="v.form.store_code"
              :isValidate="v.form.store_code.$error"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Running No."
              placeholder="Running No."
              type="text"
              name="running_no"
              :disabled="true"
              v-model="form.running_no"
            />
          </b-col>
          <div class="break-normal"></div>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Plant ID (Display)"
              placeholder="Plant ID"
              isRequired
              type="text"
              name="plant_id"
              v-model="form.plant_id"
              :v="v.form.plant_id"
              :isValidate="v.form.plant_id.$error"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Plant Ref ID (Customer ID)"
              placeholder="Plant Ref ID"
              isRequired
              type="text"
              name="plant_ref_id"
              v-model="form.plant_ref_number"
              :v="v.form.plant_ref_number"
              :isValidate="v.form.plant_ref_number.$error"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputSelect
              title="Type"
              size="md"
              name="branch_type"
              isRequired
              v-model="form.branch_type_id"
              :v="v.form.branch_type_id"
              :isValidate="v.form.branch_type_id.$error"
              :options="BranchTypeOptions"
              valueField="id"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Please Select Type --</b-form-select-option
                >
              </template>
            </InputSelect>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputSelect
              title="Branch Group"
              size="md"
              v-model="form.branch_group_template_id"
              :v="v.form.branch_group_template_id"
              :isValidate="v.form.branch_group_template_id.$error"
              :options="BranchOptions"
              name="branch_group"
              isRequired
              valueField="id"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Please Select Branch Group --</b-form-select-option
                >
              </template>
            </InputSelect>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="title-tabs mt-3">Branch Account</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <div class="d-flex align-items-center">
              <span class="label">
                Email <span class="text-danger">*</span></span
              >
              <span
                class="ml-auto reset-password"
                @click="modalShow"
                v-if="mode"
                >Reset Password</span
              >
            </div>
            <InputText
              textFloat=""
              placeholder="Email"
              isRequired
              type="text"
              name="customer-field"
              v-model="form.email"
              :disabled="this.$route.params.id != 0"
              :v="v.form.email"
              :isValidate="v.form.email.$error"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="12"
            xs="12"
            v-if="this.$route.params.id == 0"
          >
            <InputText
              textFloat="Password"
              placeholder="Password Must Be 6 Characters Or More."
              isRequired
              type="password"
              name="password"
              v-model="form.password"
              :v="v.form.password"
              :isShowPassword="true"
              autocomplete="new-password"
              :isValidate="v.form.password.$error"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="title-tabs mt-3">Address</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <b-row>
          <b-col sm="12" xs="12" lg="12">
            <InputText
              textFloat="Address"
              placeholder="Please add adress : house no., room, building"
              type="text"
              name="address"
              v-model="form.address"
            />
          </b-col>
          <!-- <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Street"
              placeholder="Street"
              type="text"
              name="street"
              v-model="form.street"
            />
          </b-col> -->
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Subdistrict"
              placeholder="Subdistrict"
              type="text"
              name="sub_district"
              v-model="form.subdistrict"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="District"
              placeholder="District"
              type="text"
              name="district"
              v-model="form.district"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Province"
              placeholder="Province"
              type="text"
              v-model="form.province"
              name="province"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Zip Code"
              placeholder="Zip Code"
              type="number"
              v-model="form.zip_code"
              @onKeypress="isNumber"
              name="zipcode"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Latitude No."
              placeholder="Latitude No."
              type="number"
              v-model="form.latitude"
              name="lat_lng"
              @onKeypress="isNumber"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Longitude No."
              placeholder="Longitude No."
              type="number"
              v-model="form.longitude"
              name="lat_lng"
              @onKeypress="isNumber"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalChangePassword ref="modalChangePassword" @submit="changePassword" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalChangePassword from "@/components/modal/ModalChangePassword";
export default {
  props: ["BranchOptions", "form", "v", "BranchTypeOptions", "mode", "type"],
  components: {
    InputText,
    InputSelect,
    ModalChangePassword,
  },
  data() {
    return {
      selected: null,
      styleDatetime: {
        width: "150%",
        border: "none",
      },
      open_day_from: "",
      open_day_to: "",

      optionsDate: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      daySelect: "",
    };
  },

  methods: {
    modalShow() {
      this.$refs.modalChangePassword.show();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (evt.target.value.length > 9) {
        return evt.preventDefault();
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changePassword(val) {
      this.form.password = val;

      var payload = {
        branch_id: this.form.id,
        password: val,
      };

      this.axios
        .put(`/branch/resetpassword`, payload)
        .then((res) => {
          if (res.data.result) {
            this.$swal2.fire(res.data.message, "", "success");
          } else {
            this.$swal2.fire("เกิดข้อผิดพลาด", res.data.message, "error");
          }
        })
        .catch((err) => {
          this.$swal2.fire(
            "เกิดข้อผิดพลาด",
            "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
            "error"
          );
        });
    },
  },
};
</script>
<style>
.label-text {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.custom-select option:disabled {
  background-color: #d8dbe0;
}
.label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
</style>
